'use client'

import FooterNav from './FooterNav'
import NewsletterForm from './NewsletterForm'
// import YoutubeIcon from '../../public/assets/icons/youtube.svg'
// import InstagramIcon from '../../public/assets/icons/instagram.svg'
// import FacebookIcon from '../../public/assets/icons/facebook.svg'
// import LinkedinIcon from '../../public/assets/icons/linkedin.svg'
import { SUPPORT } from '@/constants'
import { Button } from '@mui/material'
import { Slack } from '@/components/Icons/Slack'

// const socialMedia = [
//   {
//     name: 'Linkedin',
//     url: 'https://www.linkedin.com/company/milestone-systems/',
//     icon: LinkedinIcon,
//     dataCy: 'linkedin-icon-link',
//   },
//   {
//     name: 'Facebook',
//     url: 'https://www.facebook.com/milestonesys',
//     icon: FacebookIcon,
//     dataCy: 'facebook-icon-link',
//   },
//   {
//     name: 'Instagram',
//     url: 'https://www.instagram.com/milestonesystems/',
//     icon: InstagramIcon,
//     dataCy: 'instagram-icon-link',
//   },
//   {
//     name: 'Youtube',
//     url: 'https://www.youtube.com/user/Milestonesys',
//     icon: YoutubeIcon,
//     dataCy: 'youtube-icon-link',
//   },
// ]

export default function Footer() {
  return (
    <footer className="z-50 mt-auto w-full !bg-white">
      <div className="main-container flex flex-col gap-8 bg-white py-10">
        <div className="flex flex-col gap-6 lg:flex-row lg:justify-between">
          <FooterNav></FooterNav>
          <NewsletterForm></NewsletterForm>
        </div>
        <div className="flex flex-col items-center justify-between gap-3 text-secondary md:flex-row">
          <span className="text-sm">©Project Hafnia 2025</span>
          <Button
            color="inherit"
            variant="outlined"
            href={SUPPORT.slack}
            startIcon={<Slack />}
            target="_blank"
            size="small"
            className="text-secondary"
          >
            Join us on Slack
          </Button>
          {/* <div className="social-links mt-14">
            <ul className="flex flex-row">
              {socialMedia.map((item, idx) => {
                return (
                  <li key={idx} className={idx !== 0 ? 'ml-7' : ''}>
                    <Link
                      target="blank"
                      href={item.url}
                      data-cy={item.dataCy}
                      className={styles['social-links-hover']}
                    >
                      <Image src={item.icon} alt=""></Image>
                    </Link>
                  </li>
                )
              })}
            </ul>
          </div> */}
        </div>
      </div>
    </footer>
  )
}
