'use client'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Chip, Menu, MenuItem } from '@mui/material'
import classNames from 'classnames'
import Image from 'next/image'
import Link from 'next/link'
import TrainingAASIcon from '@public/assets/illustrations/logos/large/training_aas.svg'
import VlmAASIcon from '@public/assets/illustrations/logos/large/vlm_aas.svg'
import { FC, useState } from 'react'
import { SERVICES_HREFS, TRAINING_AAS_DASHBOARD_HREFS } from '@/constants'
import { HeaderTheme } from '@/components/Header/constants'

export const ServicesDropdown = ({ theme }: { theme: HeaderTheme }) => {
  const [settingsMenuEl, setSettingsTypesMenuEl] = useState<null | HTMLElement>(
    null,
  )
  const isSettingsMenuOpen = Boolean(settingsMenuEl)
  const onSettingsMenuClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setSettingsTypesMenuEl(event.currentTarget)
  }

  const handleSettingsMenuClose = () => {
    setSettingsTypesMenuEl(null)
  }
  return (
    <>
      <div
        className={classNames('flex cursor-pointer items-center', {
          'hover:text-blue-200': theme === 'dark',
          'hover:text-blue-800': theme === 'light',
          'text-blue-200': theme === 'dark' && isSettingsMenuOpen,
          'text-blue-800': theme === 'light' && isSettingsMenuOpen,
        })}
        onClick={onSettingsMenuClick}
      >
        <span
          className="block h-full hover:underline"
          id="services-dropdown-label"
          aria-controls={isSettingsMenuOpen ? 'settings-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isSettingsMenuOpen ? 'true' : undefined}
        >
          Services
        </span>
        <KeyboardArrowDown
          className={classNames('h-4 w-4', {
            'rotate-180': isSettingsMenuOpen,
          })}
        />
      </div>
      <Menu
        id="settings-menu"
        anchorEl={settingsMenuEl}
        open={isSettingsMenuOpen}
        className="p-0"
        onClose={handleSettingsMenuClose}
        MenuListProps={{
          'aria-labelledby': 'services-dropdown-label',
        }}
      >
        <div className="flex flex-col gap-2 px-2">
          <ServiceMenuItem
            description="Ethical video data library and model training for computer vision developers"
            label="Training-aaS"
            icon={TrainingAASIcon}
            href={TRAINING_AAS_DASHBOARD_HREFS.EXPERIMENTS}
            iconContainerClassName="bg-green-50"
          />
          <ServiceMenuItem
            description="A high-performant VLM engineered to tackle real-world traffic challenges"
            label="VLM-aaS"
            icon={VlmAASIcon}
            href={SERVICES_HREFS.VLM_AAS}
            iconContainerClassName="bg-orange-50"
            chip={
              <Chip
                label="Coming soon"
                size="small"
                color="warning"
                className="bg-orange-1000 font-semibold"
              />
            }
          />
        </div>
      </Menu>
    </>
  )
}

interface ServicesMenuItemProps {
  label: string
  description: string
  href: string
  icon: string
  iconContainerClassName: string
  chip?: React.ReactNode
}
const ServiceMenuItem: FC<ServicesMenuItemProps> = ({
  description,
  href,
  icon,
  label,
  iconContainerClassName,
  chip = null,
}) => {
  return (
    <Link href={href}>
      <MenuItem className="flex items-center gap-2 p-2">
        <div
          className={classNames(
            'flex h-16 w-16 items-center justify-center rounded-full p-2',
            iconContainerClassName,
          )}
        >
          <Image
            src={icon}
            alt={`${label} service icon`}
            className="h-full w-full"
          />
        </div>
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-2">
            <h6 className="text-h6 font-bold leading-h6">{label}</h6>
            {chip}
          </div>
          <span className="text-sm">{description}</span>
        </div>
      </MenuItem>
    </Link>
  )
}
