'use client'
import { internalNav } from '@/constants'
import styles from '@/components/home.module.css'
import {
  SERVICES_HREFS,
  SUPPORT,
  TRAINING_AAS_DASHBOARD_HREFS,
} from '@/constants'
import Link from 'next/link'

export default function FooterNav() {
  return (
    <div
      className={[
        styles['footer-nav'],
        // 'footer-nav flex w-full max-w-xl justify-between gap-4 lg:max-w-sm',
        'footer-nav flex gap-4 md:gap-[54px]',
      ].join(' ')}
    >
      <div className="flex flex-col gap-2">
        <p className="text-p2 font-bold text-black">Product</p>
        <ul className="flex flex-col gap-2">
          <li className="">
            <Link
              href={internalNav.dataLibrary}
              data-cy="data-library-link"
              className="text-sm text-black"
            >
              Data Library
            </Link>
          </li>
          <li className="">
            <Link
              href={TRAINING_AAS_DASHBOARD_HREFS.EXPERIMENTS}
              data-cy="Training-aaS-link"
              className="text-sm text-black"
            >
              Training-aaS
            </Link>
          </li>
          <li className="">
            <Link
              href={SERVICES_HREFS.VLM_AAS}
              data-cy="VLM-aaS-link"
              className="text-sm text-black"
            >
              VLM-aaS
            </Link>
          </li>
          <li className="text-sm">
            <Link
              href={SUPPORT.docs}
              data-cy="documentation-link"
              className="text-sm text-black"
              target="_blank"
              rel="noopener noreferrer"
            >
              Documentation
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-p2 font-bold text-black">Legal</p>
        <ul className="flex flex-col gap-2">
          <li className="">
            <Link
              href={internalNav.cookiePolicy}
              data-cy="cookie-policy-link"
              className="text-sm text-black"
            >
              Cookie Policy
            </Link>
          </li>
          <li className="">
            <Link
              href={internalNav.termsOfService}
              data-cy="terms-link"
              className="text-sm text-black"
            >
              Terms & Conditions
            </Link>
          </li>
          <li className="">
            <Link
              href={internalNav.privacyPolicy}
              data-cy="privacy-policy-link"
              className="text-sm text-black"
            >
              Privacy Policy
            </Link>
          </li>
        </ul>
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-p2 font-bold text-black">Company</p>
        <ul className="flex flex-col gap-2">
          <li className="">
            <Link
              href={internalNav.aboutUs}
              data-cy="about-us-link"
              className="text-sm text-black"
            >
              About Us
            </Link>
          </li>
          <li className="">
            <Link
              href={internalNav.contactUs}
              data-cy="contact-us-link"
              className="text-sm text-black"
            >
              Contact Us
            </Link>
          </li>
          <li className="">
            <Link
              href={internalNav.ethics}
              data-cy="ethics-link"
              className="text-sm text-black"
            >
              Ethics
            </Link>
          </li>
        </ul>
      </div>
    </div>
  )
}
