'use client'
import { internalNav } from '@/constants'
import Link from 'next/link'

export default function NewsletterForm() {
  return (
    <div className="gap relative flex w-full flex-col gap-3.5 md:max-w-[430px]">
      <p className="text-p2 font-bold">Subscribe to Project Hafnia News</p>
      <div className="flex flex-row items-stretch">
        <iframe
          src="https://embeds.beehiiv.com/6245ab6b-73ca-4134-8b3a-83c3ae00cb4b?slim=true"
          data-test-id="beehiiv-embed"
          className={'w-full'}
          height="52"
          frameBorder="0"
          scrolling="no"
          style={{
            margin: 0,
            borderRadius: '0px !important',
            backgroundColor: 'transparent',
          }}
        />
      </div>
      <div className="flex items-center gap-1 text-sm">
        <span>
          By subscribing, I confirm that I want to receive email marketing from
          Milestone related to Milestone products, services, and activities, and
          I acknowledge that my contact information will be handled in
          accordance with our
          <Link
            href={internalNav.privacyPolicy}
            className="anchor cms-inline-link"
            target="_blank"
          >
            Privacy Policy
          </Link>
          . You can unsubscribe at any time by clicking the opt out link in the
          bottom of the email from us.
        </span>
      </div>
    </div>
  )
}
